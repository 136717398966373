import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';

// https://laracasts.com/discuss/channels/livewire/roles242-uncaught-referenceerror-tomselect-is-not-defined?page=1&replyId=754316
import 'tom-select/dist/css/tom-select.bootstrap5.css';
window.TomSelect = require('tom-select');

import runtime from "serviceworker-webpack-plugin/lib/runtime";
if ("serviceWorker" in navigator) {
    runtime.register();
}

import app from './App.vue';
import router from './router';
import { createApp } from 'vue';
const vueApp = createApp(app);

import Console from './scripts/Console.js';
import Storage from './scripts/Storage.js';
import API from './scripts/API.js';
import Forms from './scripts/Forms.js';
import QRCode from './scripts/QRCode.js';
import Camera from './scripts/Camera.js';
import Drawing from './scripts/Drawing.js';
import Application from './scripts/Application.js';

window.storage = new Storage();
window.storage.initialize().then(() => {
    window.application = new Application({
        storage: window.storage,
        api: new API(),
        qr: new QRCode(),
        camera: new Camera(),
        drawing: new Drawing(),
        console: new Console(),
        forms: new Forms(),
    });

    window.application.initialize().then(() => {
        console.log('main.js.initialized');
        vueApp.config.globalProperties.app = window.application;
        vueApp
            .use(router, vueApp)
            .mount('#app')
    }).catch(error => {
        console.error(error || 'Error');
        document.getElementById('app_start_title').innerText = 'Kan ikke starte applikasjonen...';
        document.getElementById('app_start_description').innerHTML = 'Appen mangler grunnleggende info og kunne ikke kobles til serveren.<br>Vennligst koble til internettet og prøv igjen.';
    });
}).catch(error => {
    console.error(error || 'Error');
    document.getElementById('app_start_title').innerText = 'Kan ikke starte applikasjonen...';
    document.getElementById('app_start_description').innerHTML = 'Appen kunne ikke opprette en lokal database. Ta kontakt med support.';
});