export default class Drawing {
    constructor() {}

    initialize(storage, drawingName, drawingInfo, container_drawing_id = 'container_drawing', container_image_id = 'container_image') {
        // Get handles to relevant elements; wrapper is the parent container and viewport, holder contains the marker-container and the image element
        console.log('drawing.initialize(<storage>,' + drawingName + ',' + container_drawing_id + ',' + container_image_id + ')');
        this.storage = storage;

        // Remember the drawing name and reference point, so forms can pickup this when they need to
        this.drawingName = drawingName;
        this.drawingReference = '';
        this.drawingInfo = drawingInfo;

        // The scale is initially set at 1; the change speed when zooming in/out is set at 5%
        this.scale = 1;
        this.scale_min = 0.95;
        this.scale_change = 0.05;

        // These are used to keep and calculate the point from where to resize/pinch/zoom
        this.target = { x: 0, y: 0 };
        this.translation = { x: 0, y: 0 };

        // Used to determine mouse and touh interactions (click and drag panning for mouse, or touh and drag for screen)
        this.mouseDown = false;
        this.touchDown = false;
        this.touchDistance = 0;
        this.pointerCoord = { x: 0, y: 0 };

        // This will remember if the user was panning the drawing - in which case after the mouse-up we don't want a click
        this.drawingWasDragged = false;

        // These hold the drawing-related elements: the container and the image container
        this.wrapper = document.getElementById(container_drawing_id);
        this.holder = document.getElementById(container_image_id);
        this.holder.style.transform = `translate(0px, 0px) scale(${this.scale}, ${this.scale})`;
        this.drawing = document.getElementById('app_drawing');

        // Install some listeners for zooming the loaded drawing
        this.holder.onclick = () => { this.onDrawingClick() };
        this.holder.onwheel = () => { this.onDrawingWheel() };
        this.holder.onmousedown = () => { this.onDrawingMouseDown() };
        this.holder.onmousemove = () => { this.onDrawingMouseMove() };
        this.holder.onmouseup = () => { this.onDrawingMouseUpOrLeave() };
        this.holder.onmouseleave = () => { this.onDrawingMouseUpOrLeave() };
        this.holder.ontouchstart = () => { this.onDrawingTouchStart() };
        this.holder.ontouchend = () => { this.onDrawingTouchEnd() };
        this.holder.ontouchmove = () => { this.onDrawingTouchMove() };
        screen.orientation.onchange = () => { this.onDrawingOrientationChange() };

        // When the user clicks the marker for a new point
        document.getElementById('new_marker').onclick = () => { this.markerNewClick() };
    }

    loadDrawing(drawing, info, show, id = 'app_drawing') {
        // Variable <info> contains relevant information to send to the form
        // Variable <show> contains info to display so the user knows what drawing is shown
        console.log('drawing.loadDrawing(' + drawing + ',<info>,<show>,' + id + ')');
        let self = this;

        // The user will come back to this drawing after closing the form that was opened from here
        window.application.formOpener = 'drawing';

        // Load the blob from the indexedDB
        window.application.storage.loadBlob('drawings.' + drawing, id)
            .then(() => {
                let htmlDrawingInfo = [];
                for (let key in show) {
                    htmlDrawingInfo.push(window.application.escapeHTML(key) + ': ' + window.application.escapeHTML(show[key]));
                }
                document.getElementById('container_drawing_info').innerHTML = htmlDrawingInfo.join('<br>');

                document.getElementById('container_vue').hidden = true;
                document.getElementById('container_app').hidden = true;
                document.getElementById('container_drawing').hidden = false;
                document.getElementById('container_drawing_info').hidden = false;
                window.application.popupInfoClose();

                // Initialize the drawing functions 
                // Using even 2 requestAnimationFrame did not work, so we'll give it 1/10th of a second to paint, followed by one requestAnimationFrame :-(
                window.setTimeout(() => {
                    requestAnimationFrame(() => {
                        self.initialize(window.application.storage, drawing, info);
                        self.markersDisplay();
                    });
                }, 100);
            });
    }

    unloadDrawing(id = 'container_drawing') {
        console.log('drawing.unloadDrawing(' + id + ')');
        let self = this;

        // Hide the element, set the image to a transparent pixel
        let el = document.getElementById(id);
        el.hidden = true;
        el.src = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+P+/HgAFhAJ/wlseKgAAAABJRU5ErkJggg==";

        // The user no longer will come back to this drawing after closing the form that was opened from here, but instead will go back to the list
        window.application.formOpener = 'list';

        // Forms no longer should pickup the drawing reference
        self.drawingName = '';
        self.drawingReference = '';
        self.drawingInfo = {};

        document.getElementById('container_drawing_info').hidden = true;
        document.getElementById('container_drawing').hidden = true;
        document.getElementById('container_vue').hidden = false;
    }

    calculateAxis(x, y, axisInfo) {
        console.log('drawing.calculateAxis(' + x + ',' + y + ',<axisInfo>)');

        // If no axis info is available, exit rightaway
        if (!axisInfo.x || !axisInfo.y || !axisInfo.distances) {
            return {
                axis_1: '',
                axis_2: ''
            }
        }

        // Sort the x-values and y-values by value
        let x_values = [];
        for (let key in axisInfo.x) {
            x_values.push({ key: key, value: axisInfo.x[key] });
        }
        x_values.sort((a, b) => { return a.value - b.value });
        let y_values = [];
        for (let key in axisInfo.y) {
            y_values.push({ key: key, value: axisInfo.y[key] });
        }
        y_values.sort((a, b) => { return a.value - b.value });

        // Lookup the approximate axis
        // Compensate for inaccurate clicking by using half of a step
        let key_x = -1;
        let compare_x = parseFloat(x) + (axisInfo.distances.x / 2);
        for (let cnt in x_values) {
            if (compare_x > x_values[cnt].value) {
                key_x = cnt;
            }
        }
        let key_y = -1;
        let compare_y = parseFloat(y) + (axisInfo.distances.y / 2);
        for (let cnt in y_values) {
            if (compare_y > y_values[cnt].value) {
                key_y = cnt;
            }
        }

        let result_x = key_x == -1 ? '' : x_values[key_x].key;
        let result_y = key_y == -1 ? '' : y_values[key_y].key;
        if (result_x == '' || result_y == '') {
            result_x = '';
            result_y = '';
        } else {
            // Find the position inside the axis cell
            // axisInfo.distances.step = the steps to measure with, e.g. 0.5 means: +0.0, +0.5, +1.0, +1.5 etc
            // axisInfo.distances.x = the percentage for x-steps, e.g. 0.2138 for each step
            // axisInfo.distances.y = the percentage for y-steps, e.g. 0.3013 for each step
            x = x - x_values[key_x].value;
            let amount_x = parseInt(x / axisInfo.distances.x);
            result_x += '+' + (amount_x * axisInfo.distances.step).toFixed(1);

            y = y - y_values[key_y].value;
            let amount_y = parseInt(y / axisInfo.distances.y);
            result_y += '+' + (amount_y * axisInfo.distances.step).toFixed(1);
        }

        return {
            axis_1: result_x,
            axis_2: result_y
        }
    }

    onDrawingContextMenu() {
        // This is to disable the accidental right-click on images
        let event = window.event;

        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    onDrawingClick() {
        console.log('drawing.onDrawingClick()');
        let self = this;

        let event = window.event;
        if (!self.drawingWasDragged) {
            // Calculate the percentages (50,50 is obviously the middle of the drawing)
            // Set this to a global so the code that initializes the forms can pickup this value
            let x = parseFloat(100 * event.offsetX / self.holder.offsetWidth).toFixed(4);
            let y = parseFloat(100 * event.offsetY / self.holder.offsetHeight).toFixed(4);
            self.drawingReference = x + ',' + y + '@' + self.drawingName.replace('/', '_');

            // Auto-calculate the axis, if possible
            let axis = self.calculateAxis(x, y, self.drawingInfo.axis);
            self.drawingInfo.axis_1 = axis.axis_1;
            self.drawingInfo.axis_2 = axis.axis_2;

            // Put down a new marker
            let marker = document.getElementById('new_marker');
            marker.src = require("/assets/marker.png");
            marker.style.left = event.offsetX + 'px';
            marker.style.top = event.offsetY + 'px';
            marker.style.visibility = 'visible';

            // Calculate the html buttons
            let innerHTML = '';
            let forms = this.storage.get('info.forms_info');
            for (let form in forms) {
                if (forms[form].drawings) {
                    innerHTML += '<button ' +
                        'class="btn btn-primary btn-sm mb-2 me-2" ' +
                        'onclick="document.getElementById(\'new_marker\').style.visibility=\'hidden\';window.application.loadForm(\'' + forms[form].key + '\')' + '">' +
                        forms[form].title +
                        '</button>';
                }
            }

            // Show the modal with the buttons, with a very light backdrop (so the user sees the marker), the modal towards the bottom of the screen and only one cancel button
            document.getElementById('infoModalCancel').hidden = false;
            document.getElementById('infoModalDescription').innerHTML = innerHTML;
            if (axis.axis_1 == '') {
                document.getElementById('infoModalLabel').innerHTML = 'Velg type (koord. ' +
                    '<input type="text" style="border:0;padding:0;width:' + String(x).length + 'ch" value="' + x + '">' + ', ' +
                    '<input type="text" style="border:0;padding:0;width:' + String(y).length + 'ch" value="' + y + '">' + ')';
            } else {
                document.getElementById('infoModalLabel').innerText = 'Velg type (akse ' + axis.axis_1 + ', ' + axis.axis_2 + ')';
            }

            let height = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight);
            let modal = document.getElementById('infoModal');
            modal.style.top = (height - 330) + 'px';
            modal._modal.show();

            // Fix the backdrop
            let backdrop = document.querySelector('.modal-backdrop.show');
            backdrop.style.opacity = '0.05';

            // Hide the top right close and OK buttons
            // The reason for this is that we need to do a onclick on the cancel to reset stuff afterwards, so technical reason
            let btns = document.querySelectorAll('#infoModal .btn-close, #infoModalOK');
            for (let btn of btns) {
                btn.hidden = true;
            }

            // Do stuff when the cancel and ok buttons are clicked
            document.getElementById('infoModalCancel').onclick = () => {
                document.getElementById('new_marker').style.visibility = 'hidden';
            }
        }
    }

    onDrawingWheel() {
        console.log('drawing.onDrawingWheel()');
        let self = this;
        let event = window.event;

        if (event) {
            try {
                event.preventDefault();

                self.holder = document.getElementById('container_image');
                self.wrapper = document.getElementById('container_drawing');

                let mouse = {
                    x: event.clientX - (self.wrapper.offsetLeft + self.wrapper.clientLeft - window.scrollX),
                    y: event.clientY - (self.wrapper.offsetTop + self.wrapper.clientTop - window.scrollY)
                };

                self.target.x = (mouse.x - (self.translation.x)) / self.scale;
                self.target.y = (mouse.y - (self.translation.y)) / self.scale;

                if (event.deltaY < 0) {
                    if (window.innerWidth / (self.drawing.naturalWidth / self.scale) < 3) {
                        self.scale *= (1 + self.scale_change);
                    }
                } else {
                    if (self.scale >= (self.scale_min + self.scale_change)) {
                        self.scale *= (self.scale_min - self.scale_change);
                    } else {
                        self.scale = self.scale_min;
                    }
                }
                self.translation.y = -self.target.y * self.scale + mouse.y;
                self.translation.x = -self.target.x * self.scale + mouse.x;

                // Snap back into place
                if (self.scale == self.scale_min) {
                    self.translation.x = 0;
                    self.translation.y = 0;
                }
                self.holder.style.transform = `translate(${self.translation.x}px, ${self.translation.y}px) scale(${self.scale}, ${self.scale})`;
                self.markersResize();
            } catch (error) {
                console.error(error);
            }
        }
    }

    onDrawingMouseDown() {
        console.log('drawing.onDrawingMouseDown()');
        let self = this;
        let event = window.event;

        if (event && event.button == 0) {
            self.mouseDown = true;
            self.drawingWasDragged = false;
            self.pointerCoord.x = event.clientX - (self.translation.x);
            self.pointerCoord.y = event.clientY - (self.translation.y);
        }
    }

    onDrawingMouseMove() {
        let event = window.event;
        if (event) {
            event.preventDefault();
            let self = this;

            // Remember when mouse-release: the user panned the drawing, so no click please
            self.drawingWasDragged = true;

            if (self.mouseDown) {
                console.log('drawing.onDrawingMouseMove()');
                self.translation.x = event.clientX - self.pointerCoord.x;
                self.translation.y = event.clientY - self.pointerCoord.y;
                self.holder.style.transform = `translate(${self.translation.x}px, ${self.translation.y}px) scale(${self.scale}, ${self.scale})`;
            }
        }
    }

    onDrawingMouseUpOrLeave() {
        console.log('drawing.onDrawingMouseUpOrLeave()');
        let self = this;
        self.mouseDown = false;

        // Snap back into place
        if (self.translation.x > 0) {
            self.translation.x = 0;
        }
        if (self.translation.y > 0) {
            self.translation.y = 0;
        }
        self.holder.style.transform = `translate(${self.translation.x}px, ${self.translation.y}px) scale(${self.scale}, ${self.scale})`;
    }

    onDrawingTouchStart() {
        console.log('drawing.onDrawingTouchStart()');
        let self = this;
        let event = window.event;

        if (event) {
            self.touchDown = true;
            self.pointerCoord.x = event.touches[0].clientX - (self.translation.x);
            self.pointerCoord.y = event.touches[0].clientY - (self.translation.y);
        }
    }

    onDrawingTouchEnd() {
        console.log('drawing.onDrawingTouchEnd()');
        let self = this;

        self.touchDown = false;
        self.touchDistance = 0;
    }

    onDrawingTouchMove() {
        console.log('drawing.onDrawingTouchMove()');
        let self = this;
        let event = window.event;

        if (event) {
            switch (event.touches.length) {
                case 1:
                    if (self.touchDown) {
                        let touch = {
                            x: event.touches[0].clientX,
                            y: event.touches[0].clientY
                        }
                        self.translation.x = touch.x - self.pointerCoord.x;
                        self.translation.y = touch.y - self.pointerCoord.y;

                        // Limit scale to minimum, snap back in place
                        if (self.scale <= self.scale_min) {
                            self.scale = self.scale_min;
                            self.translation.x = 0;
                            self.translation.y = 0;
                        }
                        self.holder.style.transform = `translate(${self.translation.x}px, ${self.translation.y}px) scale(${self.scale}, ${self.scale})`;
                    }
                    break;

                case 2:
                    // Two fingers, meaning zooming/pinching
                    var distance = Math.hypot(
                        event.touches[0].clientX - event.touches[1].clientX,
                        event.touches[0].clientY - event.touches[1].clientY
                    );

                    if (self.touchDistance != 0) {
                        let center = {
                            x: (event.touches[0].clientX + event.touches[1].clientX) / 2,
                            y: (event.touches[0].clientY + event.touches[1].clientY) / 2
                        };
                        self.target.x = (center.x - (self.translation.x)) / self.scale;
                        self.target.y = (center.y - (self.translation.y)) / self.scale;
                        self.scale = self.scale * (distance / self.touchDistance);
                        self.translation.x = -self.target.x * self.scale + center.x;
                        self.translation.y = -self.target.y * self.scale + center.y;

                        // Limit scale to its minimum, snap back in place
                        if (self.scale <= self.scale_min) {
                            self.scale = self.scale_min;
                            self.translation.x = 0;
                            self.translation.y = 0;
                        }
                        self.holder.style.transform = `translate(${self.translation.x}px,${self.translation.y}px) scale(${self.scale}, ${self.scale})`;
                        self.markersResize();
                    }
                    self.touchDistance = distance;
                    break;

                default:
                    // We dont deal with 3 or more fingers (or zero)
                    break;
            }
        }
    }

    onDrawingOrientationChange() {
        console.log('drawing.onDrawingOrientationChange()');
        let self = this;
        self.drawingReset();
    }

    drawingReset() {
        console.log('drawing.drawingReset()');
        let self = this;

        self.markersClear();

        document.getElementById('infoModal')._modal.hide();

        setTimeout(() => {
            requestAnimationFrame(() => {
                self.target = { x: 0, y: 0 };
                self.translation = { x: 0, y: 0 };
                self.pointerCoord = { x: 0, y: 0 };
                self.touchDistance = 0;
                self.scale = self.scale_min;
                self.holder.style.transform = `translate(0px,0px) scale(${self.scale}, ${self.scale})`;
                self.markersDisplay();
            });
        }, 50);
    }

    drawingClick() {
        console.log('drawing.drawingClick()');
    }

    markersLoad(drawing) {
        console.log('drawing.markersLoad(' + drawing + ')');
        let self = this;

        let result = [];
        let forms = self.storage.get('info.forms_info');
        for (let form in forms) {
            if (forms[form].drawings) {
                let rows = self.storage.getAll('created.' + forms[form].key);
                for (let row in rows) {
                    if (rows[row].data.reference) {
                        let tmp = rows[row].data.reference.split('@');
                        if (tmp[1] == drawing.replace('/', '_')) {
                            let coords = tmp[0].split(',');
                            result.push({
                                id: rows[row].id,
                                x: coords[0],
                                y: coords[1],
                                type: forms[form].key
                            });
                        }
                    }
                }
            }
        }

        return result;
    }

    markersToggle(elButton) {
        console.log('drawing.markersToggle()');
        let markers = document.getElementsByClassName('marker');
        for (var cnt = 0; cnt < markers.length; cnt++) {
            markers[cnt].hidden = !markers[cnt].hidden;
        }
        if (elButton) {
            elButton.style.opacity = (elButton.style.opacity == '0.2' ? '0.5' : '0.2');
        }
    }

    markersResize() {
        let markers = document.querySelectorAll('#new_marker, .marker');
        let size = 1 / this.scale;
        for (let marker of markers) {
            marker.style.transform = `scale(${size}, ${size})`;
        }
    }

    markersClear() {
        console.log('drawing.markersClear()');
        let containerEl = document.getElementById('container_markers');
        containerEl.innerHTML = '';
    }

    markersDisplay() {
        console.log('drawing.markersDisplay()');
        let self = this;

        // Load the markers from the database
        if (self.drawingName) {
            let markers = self.markersLoad(self.drawingName);
            let containerEl = document.getElementById('container_markers');
            let markerHTML = '';

            for (let marker of markers) {
                markerHTML += '<img ' +
                    'src="' + require("/assets/marker.png") + '"' +
                    'class="marker" ' +
                    'style="' + self.markerCalcPosition(marker.x, marker.y) + '"' +
                    'id="' + marker.id + '"' +
                    'data-type="' + marker.type + '"' +
                    'onclick="application.drawing.markerClick(this)"' +
                    '/>';
            }
            containerEl.innerHTML = markerHTML;
        }
    }

    markerCalcPosition(x, y) {
        let left = 0;
        let top = 0;
        if (this.holder) {
            left = (this.holder.offsetWidth / 100) * x;
            top = (this.holder.offsetHeight / 100) * y;
        } else {
            console.error('markerCalcPosition: this.holder not available.');
        }
        return `left:${left}px; top:${top}px; transform:scale(${1/this.scale}, ${1/this.scale})`;
    }

    markerNewClick() {
        console.log('drawing.markerNewClick()');
        let event = window.event;
        event.preventDefault();
        event.stopPropagation();

        let marker = document.getElementById('new_marker');
        marker.style.visibility = 'hidden';
    }

    markerClick(marker) {
        console.log('drawing.markerClick()');
        let self = this;

        // Stop the click here (so we won't get a click on the drawing)
        let event = window.event;
        event.preventDefault();
        event.stopPropagation();

        // Get the form info for this record so we know what to show and where to go
        let forms = self.storage.get('info.forms_info');
        let form = null;
        for (let cnt in forms) {
            if (forms[cnt].key == marker.dataset.type) {
                form = forms[cnt];
            }
        }

        // Calculate an overview over the values
        let innerHTML = '';
        let row = self.storage.get('created.' + marker.dataset.type + '.' + marker.id);
        for (let column in form.columns) {
            innerHTML += window.application.escapeHTML(column) + ': <span class="' + form.columns[column] + '">' + window.application.escapeHTML(row.data[form.columns[column]]) + '</span><br>';
        }

        // Add if this was uploaded already or not
        innerHTML += 'Status: <span class="status">' + row.status[0].toUpperCase() + row.status.substring(1) + '</span><br>';

        // Give the ok button something to do
        document.getElementById('infoModalOK').onclick = () => {
            window.application.loadForm(marker.dataset.type, row);
        };

        // Show the modal with the buttons, with a very light backdrop (so the user sees the marker), the modal towards the bottom of the screen and only one cancel button
        document.getElementById('infoModalLabel').innerText = form.title;
        document.getElementById('infoModalDescription').innerHTML = innerHTML;

        document.getElementById('infoModal')._modal.show();
    }
}