<template>
  <div>
    <nav class="navbar navbar-expand-md navbar-dark sticky-top bg-dark">
      <div class="container-fluid">
        <router-link class="navbar-brand" to="/" replace>{{ name }}</router-link>
        <button v-if="!isLogin" class="navbar-toggler" @click="toggleMenu()" type="button" aria-controls="navbarCollapse"
          aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div v-if="!isLogin" class="navbar-collapse collapse" id="navbarCollapse">
          <ul class="navbar-nav ms-auto mb-2 mb-md-0">
            <li class="nav-item"><router-link class="nav-link active" to="/sync" replace>Synkroniser</router-link></li>
            <li class="nav-item"><router-link class="nav-link active" to="/about" replace>Om Pyrodok</router-link></li>
            <li class="nav-item"><a class="nav-link active" @click="doLogout()">Logg av</a></li>
          </ul>
        </div>
      </div>
    </nav>

    <div v-if="isMobile() || !isMobile()">
      <div id="container_vue" class="container">
        <router-view />
      </div>
      <div id="container_app" class="container p-3 mb-4" hidden>
        Skjemaene lastes inn her.
      </div>
      <div id="container_app_form" class="container p-3 mb-4" hidden>
        HTML-forms lastes inn her.
      </div>
      <div id="container_drawing" class="m-0 mb-4 p-0" hidden>
        <button id="btnDrawingClose" type="button" class="btn-close float-end p-2" aria-label="Close"
          onclick="application.drawing.unloadDrawing()"></button>
        <button id="btnDrawingToggleMarkers" type="button" class="btn-close float-end p-2" aria-label="Close"
          onclick="application.drawing.markersToggle(this)"><i class="fa fa-eye-slash" aria-hidden="true"></i></button>
        <div id="container_image">
          <div id="container_markers"></div>
          <img src="" id="new_marker" alt="">
          <img src="" id="app_drawing" alt="" @contextmenu="onDrawingContextMenu">
        </div>
      </div>
      <div id="container_drawing_info" class="px-3 mb-3 small text-muted" hidden></div>
    </div>

    <div v-else class="warning text-center mt-5">
      <p>
        Applikasjonen kan kun brukes mobilt.
      </p>
      <p>
        Gå til {{ server }} på nettbrettet eller mobilen,<br>
        eller skann denne QR-koden:
      </p>
      <p>
        <img :src="qrSource" :alt="server" />
      </p>
      <p>
        <small class="tiny">Har du ingen skanner på mobilen?<br>Bruk https://seeqr.eu</small>
      </p>
    </div>

    <div class="modal fade" id="infoModal" tabindex="-1" aria-labelledby="infoModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="infoModalLabel">...</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body overflow-auto" style="max-height:350px">
            <p class="description" id="infoModalDescription">...</p>
          </div>
          <div class="modal-footer">
            <button type="button" id="infoModalCancel" class="btn btn-secondary me-auto" data-bs-dismiss="modal">Avbryt</button>
            <button type="button" id="infoModalOK" class="btn btn-primary" data-bs-dismiss="modal">OK</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="scanQRModal" tabindex="-1" aria-labelledby="scanQRModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="scanQRModalLabel">QR-kode</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
              onclick="application.qr.cancel()"></button>
          </div>
          <div class="modal-body overflow-auto text-center" style="min-height:300px; max-height:700px">
            <video id="qr-video" style="width:100%"></video>
            <!--
            <div id="qr-handler"></div>
            <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded" v-if="qrModalVisible && false">
            </StreamBarcodeReader>
            -->
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="cameraModal" tabindex="-1" aria-labelledby="cameraModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body overflow-auto text-center px-0 mx-0" style="min-height:300px; max-height:700px">
            <div id="cameraWrapper" style="overflow:hidden">
              <button type="button" class="btn btn-camflash" id="cameraModalFlash" onclick="application.camera.toggleFlash()" aria-label="Flash" style="z-index:1;position:absolute;top:1.2rem;left:2rem"><i class="fas fa-bolt"></i></button>
              <button type="button" class="btn btn-camswitch" id="cameraModalSwitch" onclick="application.camera.start('next')" aria-label="Switch" style="z-index:1;position:absolute;top:1.2rem;left:4.5rem" hidden><i class="fas fa-redo"></i></button>
              <button type="button" class="btn-close btn-camclose" onclick="application.camera.stop()" aria-label="Close" style="z-index:1;position:absolute;top:1.5rem;right:2rem"></button>
              <button type="button" class="btn-camshot" id="cameraModalShoot" onclick="application.camera.shoot()" aria-label="Shoot" style="z-index:1"></button>
              <video id="camera-video" class="p-0 m-0"></video>
              <canvas id="camera-canvas" class="p-0 m-0" style="display:none"></canvas>
            </div>

            <div class="cameraWarning collapse mt-3">
              Kamera er ikke tilgjengelig for øyeblikket.<br>
              Vennligst juster innstillingene og gi appen tilgang.<br><br>
              Du kan prøve å starte appen på nytt.<br>
              <button class="btn btn-secondary me-auto mt-3" onclick="location.reload()">Restart app</button>
            </div>
          </div>
          <div class="cameraWarning modal-footer">
            <button class="btn btn-secondary me-auto" onclick="application.camera.start('next')">Prøv igjen</button>
            <button class="btn btn-primary" onclick="application.camera.stop()">OK</button>
          </div>
        </div>
      </div>
    </div>

    <footer class="fixed-bottom bg-dark text-center text-light small py-1" v-if="isMobile()">
      {{ name }} - <span class="small">{{ version }}</span><span v-if="!app.api.isOnline" class="small ps-2 blink"><i
          class="fas fa-wifi" style="color:red"></i></span>
    </footer>
  </div>
</template>

<script>
import { Modal } from 'bootstrap';
import { useRoute } from 'vue-router';
//import { StreamBarcodeReader } from "vue-barcode-reader";

export default {
  components: {
    //StreamBarcodeReader
  },
  data() {
    return {
      server: this.app.server(),
      name: this.app.name,
      version: this.app.version,
      user: this.app.user,
      qrModalVisible: false
    }
  },

  computed: {
    isLogin() {
      const route = useRoute();
      return (route.path == '/login');
    },

    qrSource() {
      return 'https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=' + this.server;
    },

  },
  watch: {
    qrModalVisible: function (val, old) {
      console.log("val", val, old)
    }
  },

  methods: {
    toggleMenu() {
      try {
        let el = document.getElementById('navbarCollapse');
        if (el) {
          el.classList.toggle('show');
        }
      } catch (error) {
        console.error(error);
      }
    },

    doLogout() {
      this.app.logout();
      this.$router.replace('/login');
    },

    doRestartApp() {
      this.app.logout();
      location.reload(true);
    },

    isMobile() {
      return this.app.isMobile();
    },
    onLoaded(e) {
      console.log("on loaded", e)
    },
    onDecode(result) {
      document.getElementById('qrcode').value = result;
      // for now I am calling same cancel function to hide the modal, if needed new function can be called
      this.app.qr.cancel();
      console.log("Scanned Result", result)
    },
    qrModalInit() {
      const modal = document.getElementById('scanQRModal');
      if (modal) {
        modal.addEventListener('hidden.bs.modal', () => {
          this.qrModalVisible = false;
        })
        modal.addEventListener('shown.bs.modal', () => {
          this.qrModalVisible = true;
        })

      }
    }
  },

  mounted() {
    console.log('app.mounted');

    // Initialize some functions
    this.app.qr.initialize('scanQRModal');
    this.app.camera.initialize(this.app.storage, 'cameraModal', 'cameraModalShoot', 'cameraModalSwitch', 'cameraModalFlash');
    this.qrModalInit()
    // Add the bootstrap reference to the element in the DOM
    let modal = document.getElementById('infoModal');
    modal._modal = new Modal(modal, {
      backdrop: true,
      keyboard: true,
      focus: true
    });

    // When the info modal is closed, certain properties are reset (position, opacity of the backdrop, visibility of buttons, onclick of buttons)
    modal.addEventListener('hidden.bs.modal', () => {
      let modal = document.getElementById('infoModal');
      modal.style.top = '0px';
      //let backdrop = document.querySelector('.modal-backdrop.show');
      //backdrop.style.opacity = '0.05';
      let btns = document.querySelectorAll('#infoModal button');
      for (let btn of btns) {
        btn.hidden = false;
        // Remove any onclick function - just removing the attribute didn't seem to work, so we set it to null first
        btn.onclick = null;
        btn.removeAttribute('onclick');
      }
    });
  }
};
</script>

<style>
/* https://stackoverflow.com/questions/36212722/how-to-prevent-pull-down-to-refresh-of-mobile-chrome */
html,
body {
  overscroll-behavior-y: contain;
}

/* Buttons with icons should have some spacing (unless they consist ONLY of an icon) */
button.no-icon-space i.fa,
a.no-icon-space i.fa,
button.no-icon-space svg,
a.no-icon-space svg {
  margin-right: 0;
}

button i.fa,
a i.fa,
button svg,
a svg {
  margin-right: 0.5em;
}

.tiny {
  font-size: 80%;
}

.form-label {
  margin-top: 0.5rem;
  margin-bottom: 0;
}

.nodecoration {
  text-decoration: none;
  color: inherit;
}

.missing {
  color: red;
}

.text-black-25 {
  /* Mostly used for the uploaded cloud icon */
  opacity: 0.25;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.btn-camswitch,
.btn-camflash {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
  filter: drop-shadow(2px 0 0 white) drop-shadow(0 2px 0 white) drop-shadow(-2px 0 0 white) drop-shadow(0 -2px 0 white);
}

.btn-camflash[data-flash=true] {
  opacity: 1;
  color: white;
  filter: drop-shadow(2px 0 0 gray) drop-shadow(0 2px 0 gray) drop-shadow(-2px 0 0 gray) drop-shadow(0 -2px 0 gray)
}

.btn-camclose {
  filter: drop-shadow(2px 0 0 white) drop-shadow(0 2px 0 white) drop-shadow(-2px 0 0 white) drop-shadow(0 -2px 0 white);
}

.btn-camshot {
  position: absolute;
  bottom: 1.7rem;
  left: calc(50% - 15px);
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 2px solid #000;
  opacity: 0.75;
  background-color: #fff;
  filter: drop-shadow(0px 0px 2px white);
}

label {
  white-space: pre-wrap;
}

#container_image {
  cursor: crosshair;
}

/* tomSelect for selects */
.ts-wrapper .option p {
  display: block;
  padding: 0;
  margin: 0;
}

.ts-wrapper .option p:nth-child(n+2) {
  font-size: 12px;
  display: block;
  color: #a0a0a0;
}

fieldset:disabled * {
  /* This disables mouse interactions with tom-select elements */
  pointer-events: none;
}

fieldset:disabled .ts-wrapper {
  background-color: #e9ecef;
}

fieldset:disabled svg {
  display: none;
}

fieldset:disabled .btn,
fieldset:disabled .btn-check {
  opacity: 0.3 !important;
  filter: saturate(0);
}

/* Position the clear button (x) to the left of the dropdown caret */
.plugin-clear_button.focus.has-items .clear-button,
.plugin-clear_button:hover.has-items .clear-button {
  margin-right: 2em !important;
}

/* When presenting the specified values, honor the white spacing and cr/lfs */
#infoModalDescription {
  white-space: pre-wrap;
}

#infoModalDescription td {
  width: 50%;
}

#qr-video {
  width: 80vw;
}

/* Pictures that were taken shouldn't be too large when displayed */
#pictures .picture {
  width: 30%;
  margin: 0 0.5em 0.5em 0;
  border: 1px solid gray;
}

#container_drawing {
  width: 100vw;
  display: inline-block;
  overflow: hidden;
  border-bottom: 3px solid #ddd;
  /* This gives extra space to fully show a marker, in case it's at 100% */
  padding-bottom: 32px !important;
}

#btnDrawingClose {
  position: absolute;
  top: 3.5rem;
  right: 0;
  z-index: 1;
}

#btnDrawingToggleMarkers {
  position: absolute;
  overflow: hidden;
  top: 5.3rem;
  z-index: 1;
  /* This is styled as a btn-close, so we only have minor adjustments */
  background-image: none;
  right: 2px;
}

#btnDrawingClose:focus,
#btnDrawingToggleMarkers:focus {
  box-shadow: none;
}

#container_image {
  transform-origin: top left;
  position: relative;
}

#container_markers {
  position: relative;
}

#new_marker,
#container_markers .marker {
  position: absolute;
  transform-origin: top left;
}

#new_marker:hover,
#container_markers .marker:hover {
  cursor: pointer;
}

#qr-handler video {
  width: 90vw;
}

#app_drawing {
  width: 100vw;
  z-index: 0;
  display: block;
  /*   transition: transform .4s; */
}

/* Blinking */
@keyframes blinker {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.blink {
  animation: blinker 1s cubic-bezier(.5, 0, 1, 1) infinite alternate;
}

#scanQRModal img[alt="Info icon"] {
	display: none;
}
#scanQRModal #html5-qrcode-button-camera-permission:after {
	display: block;
	content: "Bruk kamera";
	visibility: visible;
	background-color: lightgray;
	padding: 0.75em;
}
#scanQRModal #html5-qrcode-button-camera-permission {
	visibility: hidden;
}
</style>